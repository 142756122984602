@import 'normalize';
@import 'code_style';
@import 'flexible';
* {
    --bg_color: #1C1C1E;
    --header_bg: #24292E;
    --content_bg: #2F3437;
    --archive_font: #e0bac9;
    --archive_title: #d794af;
    --archive_title_hover: #e0bac9;
    --name_font: #c5a7e0;
    --job_font: #CACCCE;
    --nav_font: #e0bac9;
    --nav_font_hover: #D4CEF2;
    --shadow_color: rgba(0, 0, 0, 1);
    --post_title: #e0d0dc;
    --post_date: #e0d0dc;
    --post_text: #CACCCE;
    --post_h3: #fff;
    --post_link: #d794af;
    --post_link_hover: #e0bac9;
    --inlinecode_text: #ff99ff;
    --blockquote: #CACCCE;
    --footer_font: #858585;
    --footer_font_a: #858585;
    --border_radius: 1px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow-x: hidden;
}

body {
    height: 100%;
    font-weight: normal;
    font-style: normal;
    font-family: -apple-system, BlinkMacSystemFont, 'Titillium Web', "Segoe UI", Roboto, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    margin: 0;
    padding: 0;
    background-color: var(--bg_color);
    transition: background-color 0.5s ease;
    background-size: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;

    @include breakpoint(medium) {
        zoom: 1;
    }
}

a {
    text-decoration: none;
}

img {
    // display: block;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.content {
    margin: 1.4em auto;
    z-index: 0;
    background: var(--content_bg);
    position: relative;
    min-height: 85%;
    border-radius: 15px;
    box-shadow: 0 0 2px 2px var(--shadow_color);
    padding-bottom: 2rem;

    @include breakpoint(medium) {
        margin-left: auto;
        margin-right: auto;
        max-width: 960px;
        padding-top: 2.5rem;
    }

    @include mobile-only {
        padding-top: 16rem;
        min-height: 75%;
        max-width: 92%;
    }
}

.main {
    padding: 0.5rem;

    @include breakpoint(medium) {
        margin-left: 22rem;
    }
}

.aboutme {
    @include breakpoint(medium) {
        max-width: 30rem;
        margin-top: 5.5rem;

        img {
            float: none;
        }
    }
    }

    @include mobile-only {
        margin-top: 2rem;
        margin-left: 1rem;
        margin-right: 1rem;
    }



::-webkit-scrollbar {
    width: 8px;
    height: 6px;
}

::-webkit-scrollbar-track {
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
    -webkit-border-radius: 0px;
    border-radius: 0px;
}

::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(0, 0, 0, 0.2);
}

.archive {
    color: var(--archive_font);

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            margin-bottom: 0.6rem;
        }
    }

    & a {
        text-decoration: none;
        color: var(--archive_title);

        &:hover,
        &:focus {
            color: var(--archive_title_hover);
        }
    }

    @include breakpoint(medium) {
        max-width: 30rem;
        margin-top: 5rem;
    }

    @include mobile-only {
        margin-top: 2rem;
        margin-left: 1.2rem;
        margin-right: 1.2rem;
    }
}

.archive_date {
    text-transform: uppercase;

    @include breakpoint(medium) {
        float: right;
    }
}

.archive_title {
    word-wrap: normal;
    @include breakpoint(medium) {
        max-width: 80%;
    }
}

.archive_type {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.archive_year {
    font-size: 1.2rem;
    margin-top: 1.4rem;
    margin-bottom: 0.6rem;
}

.header {
    background-color: var(--header_bg);
    z-index: 1;
    position: absolute;
    top: 0;
    border-radius: var(--border_radius);
    box-shadow: 0 0 2px 2px var(--shadow_color);

    @include mobile-only {
        width: 100%;
        height: 9rem;
    }

    @include breakpoint(medium) {
        width: 18rem;
        height: 100%;
        min-height: 100%;
    }

}

.header_content {
    position: absolute;
    text-align: center;

    @include breakpoint(medium) {
        margin-top: 8rem;
        width: 100%;
    }

    @include mobile-only {
        margin-top: 3rem;
        width: 100%;
    }
}

.header_circle {
    border: none;
    box-sizing: border-box;
    height: 7rem;
    width: 7rem;
    border-radius: 50%;

    img {
        @extend .header_circle;
    }

    &:hover {
        cursor: pointer;
        animation: jiggle 2s ease-in-out infinite;
    }

    @include mobile-only {
        bottom: 0;
        left: 0;
        margin-left: auto;
        margin-right: auto;
    }
}

.header_name {
    display: block;
    color: var(--name_font);
    font-size: 1.5em;
}

.header_job {
    display: block;
    margin-top: .1rem;
    color: var(--job_font);
    font-size: 0.75em;
    font-weight: 700;
}

.header_mes {
    display: block;
    margin-top: .1rem;
    color: var(--job_font);
    font-size: 0.75em;
    font-weight: 700;
}

.button {
    margin-left: auto;
    margin-right: auto;
}

.nav {
    margin-top: 1rem;

    @include mobile-only {
        display: none;
    }
}

.nav_list {
    margin: 0;
    padding: 0;
    list-style: none;

    @include mobile-only {
      margin-top:2.5rem;
        text-align: center;
    }
}

.nav_item {
    display: inline-block;
    *display: inline;
    zoom: 1;

    & a {
        color: var(--nav_font);
        font-size: 90%;

        &:hover,
        &:focus {
            color: var(--nav_font_hover);
        }
    }

    @include mobile-only {

        &:after {
            content: '/';
            color: var(--nav_font);
        }

        &:first-child::before,
        &:last-child::after {
            content: none;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

.mobile_menu {
    display: none;

    @include mobile-only {
        display: block;
        margin: -.5rem;
    }

    @include breakpoint(medium) {
        position: absolute;
        top: 1.25rem;
        right: 1.25rem;
    }
}

@keyframes jiggle {
    0% {
        transform: rotate(0);
    }

    25% {
        transform: rotate(-3deg);
    }

    50% {
        transform: rotate(0);
    }

    75% {
        transform: rotate(3deg);
    }
}



/* 4. Post
   ========================================================================== */

.post {
    color: var(--post_text);

    @include breakpoint(medium) {
        max-width: 33rem;
        margin-top: 5rem;
    }

    @include mobile-only {
        margin-top: 2rem;
        margin-left: 1rem;
        margin-right: 1rem;
    }
}

.post_title {
    color: var(--post_title);
    font-size: 1.8rem;
    line-height: 2.2rem;
    font-weight: 700;

    @include mobile-only {
        text-align: center;
        font-size: 1.5rem;
    }
}

.post_data {
    margin-top: -0.5rem;
    margin-bottom: 3.5rem;

    @include mobile-only {
        text-align: center;
        margin-top: -1rem;
    }
}

.post_date {
    color: var(--post_date);
    letter-spacing: -0.5px;
    line-height: 1;
}

.post_categories {
    line-height: 1;
    margin-left: 0.3rem;

    a {
        color: var(--nav_font);

        &:hover,
        &:focus {
            color: var(--nav_font_hover);
        }
    }
}

.post_content {
    font-size: 0.95rem;
    line-height: 1.5rem;
    color: var(--post_text);

    h1,
    h2 {
        margin-top: 2rem;
    }

    h3 {
        margin-top: 2rem;
        color: var(--post_h3);
    }

    h4 {
        margin-top: 2rem;
        margin-bottom: -.5rem;
        color: var(--post_h3);
    }

    p {
        max-width: 49rem;
    }

    a {
        color: var(--post_link);

        &:hover,
        &:focus {
            color: var(--post_link_hover);
        }
    }

    li {
        margin-bottom: 0.3rem;
    }

    pre {
        font-size: 0.85rem;
        text-decoration: none;
        display: flex;
        padding: 1rem;
        border-radius: 10px;
        white-space: pre;
        position: relative;
        width: auto;
    }

    code {
        color: var(--inlinecode_text);
        padding: .1em .4em;
        border-radius: 5px;
        font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
        font-size: .85rem;
    }

    pre code {
        overflow-x: hidden;
        color: #fafafa;
        padding: .1em;
        border: none;
    }

    img {
        border-radius: 5px;
    }

    hr {
        margin-top: 2rem;
        border: none;
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }

    blockquote {
        border-left: 5px solid var(--blockquote);
        margin: 10px 0 10px 0;
        padding: 2px 8px 2px 8px;
        font-style: italic;
    }

    dfn {
        border-bottom: dashed 1px var(--job_font);
        padding: 0 0.2rem;
        font-style: normal;
        position: relative;
    }

    dfn::after {
        content: attr(info);
        display: inline;
        position: absolute;
        top: 22px;
        left: 0;
        opacity: 0;
        width: 11rem;
        color: #fff;
        font-size: 13px;
        font-weight: 700;
        line-height: 1.5em;
        padding: 0.5rem 0.8rem;
        background: #064b4d;
        pointer-events: none;
        transition: opacity 200ms, top 200ms;
    }

    dfn::before {
        content: '';
        display: block;
        position: absolute;
        top: 12px;
        left: 20px;
        opacity: 0;
        width: 0;
        height: 0;
        border: solid transparent 5px;
        border-bottom-color: #064b4d;
        transition: opacity 200ms, top 200ms;
    }

    dfn:hover::after,
    dfn:hover::before {
        opacity: 1;
    }

    dfn:hover::after {
        top: 30px;
    }

    dfn:hover::before {
        top: 20px;
    }
}

.post_comment {
    margin-top: 8rem;
    padding: 10px;
}

.footer {
    left: 0;
    right: 0;
    bottom: 0;
    height: 2.4rem;
    text-align: center;
    color: var(--footer_font);
    font-size: 0.7rem;

    a {
        text-decoration: none;
        color: var(--footer_font_a);
    }
}

div#amzn-native-ad-0 * {
  font-family: -apple-system, BlinkMacSystemFont, 'Titillium Web', "Segoe UI", Roboto, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
  color: #d794af !important;
}

div#amzn-native-ad-0 div.amzn-native-products-list div.amzn-native-product {
  background-color: transparent !important;
}

pre > button {
  opacity: 0;
  position: absolute;
  float: right;
  top:0px;
  right:0px;
  z-index: 1;
  padding: 0.8rem;
  margin: 0.2rem;
  border-radius: inherit;
  cursor: pointer;
}

pre:hover > button,
pre > button:active,
pre > button:focus {
  opacity: 1;
}
